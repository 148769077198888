import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../components/svg/divider'
import { Link } from "gatsby"
import useTranslations from "../components/useTranslations"
import Button from '../components/button'

const ExporePage = ( ) => {

    const { 
        the_most_exclusive_ss, 
        exclusive, 
        upscaled, 
        classy, 
        erotic, 
        open_minded, 
        everyday_thousands_of_people, 
        explore, 
        party,
        nationally_known,
        play,
        whatever_after,
        bs4s
    } = useTranslations()


    return (
        <div id='innerPageWrapper'>
            <div className="relative overflow-hidden h-[600px] md:h-[800px]">
                <div className="absolute inset-0 h-full">
                    <StaticImage 
                        src="../images/site/party_entrance.jpg" 
                        alt=""
                        className="w-full h-full object-bottom object-cover"
                    />
                </div>
                <div className="relative bg-black bg-opacity-50 p-6 h-full flex items-center">
                    <div className="container font-thin text-4xl">
                        <h2 className='text-center text-primary mb-3'>{the_most_exclusive_ss}</h2>
                        <h2 className='text-center text-skin-base'>{bs4s}</h2>
                    </div>
                </div>
            </div>

            <div className='bg-black'>
                <div className="container text-center p-6 md:p-16 md:text-2xl font-thin">
                    <h2>{exclusive} &bull; {upscaled} &bull; {classy} &#x2022; {erotic} • {open_minded}</h2>
                </div>
            </div>

            <div className='flex flex-col md:flex-row md:h-[600px]'>
                <div className="md:flex-1 relative overflow-hidden h-96 md:h-full">
                    <div className="absolute inset-0">
                        <StaticImage 
                            src="../images/site/explore1.jpeg" 
                            alt=""
                            className="w-full h-full object-fill"
                        />
                    </div>
                    <div className="hidden md:flex relative bg-black bg-opacity-50 h-full w-full items-center transition ease-in-out hover:bg-opacity-0">
                        
                    </div>
                </div>
                <div className='md:flex-1 flex items-center'>
                    <div className='p-12 text-center md:text-left'>
                        <h3 className='text-primary mb-6'>Online</h3>
                        <h1 className='text-3xl uppercase'>{explore}</h1>
                        <Divider width='160' className='my-6 flex justify-center md:justify-start' color='#a76363' />
                        <p className='md:w-2/3'>
                            {everyday_thousands_of_people}
                        </p>
                        <p>
                            <Link to='/play'>
                                <Button text='Check out the app'></Button>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            <div className='flex flex-col md:flex-row md:h-[600px] bg-black'>
                <div className='md:flex-1 flex items-center md:text-right justify-end'>
                    <div className='p-12 text-center md:text-right md:w-2/3'>
                        <h3 className='text-primary mb-6'>On premise</h3>
                        <h1 className='text-3xl uppercase'>{party}</h1>
                        <Divider width='160' className='my-6 flex justify-center md:justify-end items-right' color='#a76363' />
                        <p>
                            {nationally_known}
                        </p>
                        <p>
                            <Link to='/party'>
                                <Button text='Go to parties'></Button>
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="md:flex-1 order-first md:order-last relative overflow-hidden h-96 md:h-full">
                    <div className="absolute inset-0">
                        <StaticImage 
                            src="../images/site/explore_meet.jpg" 
                            alt=""
                            className="w-full h-full object-bottom object-cover"
                        />
                    </div>
                    <div className="hidden md:flex relative bg-black bg-opacity-50 transition ease-in-out hover:bg-opacity-0 h-full items-center">
                        
                    </div>
                </div>
            </div>

            <div className='flex flex-col md:flex-row  md:h-[600px]'>
                <div className="md:flex-1 relative overflow-hidden h-96 md:h-full">
                    <div className="absolute inset-0">
                        <StaticImage 
                            src="../images/site/explore_play.jpg" 
                            alt=""
                            className="w-full h-full object-bottom object-cover"
                        />
                    </div>
                    <div className="hidden md:flex relative bg-black bg-opacity-50 p-6 h-full items-center transition ease-in-out hover:bg-opacity-0">
                        
                    </div>
                </div>
                <div className='md:flex-1 flex items-center'>
                    <div className='p-12 md:w-2/3 text-center md:text-left'>
                        <h3 className='text-primary mb-6'>Private party</h3>
                        <h1 className='text-3xl'>{play}</h1>
                        <Divider width='160' className='my-6 flex justify-center md:justify-start' color='#a76363' />
                        <p>
                            {whatever_after}
                        </p>
                        <p>
                            <a href="https://www.lifestylefever.com" target="_blank" rel="noreferrer">
                                <Button text='Create your bootycall'></Button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default ExporePage